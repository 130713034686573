import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"

import { middleware as authApiMiddleware } from "network/auth"
import { middleware as authenticationApiMiddleware } from "network/authentication"
import { middleware as budgetsApiMiddleware } from "network/budgets"
import { middleware as cardApiMiddleware } from "network/card"
import { middleware as connectionsApiMiddleware } from "network/connections"
import { middleware as entityApiMiddleware } from "network/entity"
import { errorLogger } from "network/errorLogger"
import { middleware as gpsTransactionApiMiddleware } from "network/gpsTransaction"
import { middleware as gpsTransactionsApiMiddleware } from "network/gpsTransactions"
import { listenerMiddleware } from "network/jwtExpire"
// import { middleware as logglyApiMiddleware } from "network/loggly"
import { middleware as limitsApiMiddleware } from "network/limits"
import { middleware as notificationApiMiddleware } from "network/notification"
import { middleware as notificationsApiMiddleware } from "network/notifications"
import { middleware as oauthApiMiddleware } from "network/oauth"
import { openSession } from "network/openSession"
import { middleware as paymentsApiMiddleware } from "network/payments"
import { middleware as personApiMiddleware } from "network/person"
import { middleware as receiptApiMiddleware } from "network/receipt"
import { middleware as receiptMemoApiMiddleware } from "network/receiptMemo"
import { middleware as receiptTagsApiMiddleware } from "network/receiptTag"
import { middleware as receiptsApiMiddleware } from "network/receipts"
import { middleware as signUpApiMiddleware } from "network/signUp"
import { middleware as spendControlsApiMiddleware } from "network/spendControls"
import { middleware as statementApiMiddleware } from "network/statement"
import { middleware as statementsApiMiddleware } from "network/statements"
import { middleware as tagsApiMiddleware } from "network/tags"
import { middleware as transactionsApiMiddleware } from "network/transactions"
import { middleware as twoFAApiMiddleware } from "network/twoFA"
import { middleware as verificationApiMiddleware } from "network/verification"

import reducer from "./reducer"

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    })
      .concat(authApiMiddleware)
      .concat(authenticationApiMiddleware)
      .concat(oauthApiMiddleware)
      .concat(signUpApiMiddleware)
      .concat(spendControlsApiMiddleware)
      .concat(personApiMiddleware)
      .concat(cardApiMiddleware)
      .concat(openSession)
      .concat(connectionsApiMiddleware)
      .concat(transactionsApiMiddleware)
      .concat(gpsTransactionsApiMiddleware)
      .concat(gpsTransactionApiMiddleware)
      .concat(twoFAApiMiddleware)
      .concat(limitsApiMiddleware)
      .concat(notificationsApiMiddleware)
      .concat(notificationApiMiddleware)
      .concat(paymentsApiMiddleware)
      .concat(entityApiMiddleware)
      .concat(receiptApiMiddleware)
      .concat(receiptsApiMiddleware)
      .concat(receiptMemoApiMiddleware)
      .concat(receiptTagsApiMiddleware)
      .concat(statementApiMiddleware)
      .concat(statementsApiMiddleware)
      .concat(tagsApiMiddleware)
      .concat(budgetsApiMiddleware)
      .concat(verificationApiMiddleware)
      .concat(listenerMiddleware.middleware)
      .concat(errorLogger)
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

setupListeners(store.dispatch)
