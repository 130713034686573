import { Column, IStackProps } from "native-base"
import { forwardRef } from "react"

const BoxCard = ({ children, ...props }: IStackProps, ref: React.Ref<unknown> | undefined): JSX.Element => {
  return (
    <Column
      ref={ref}
      display="block"
      bg="tertiary.50"
      borderRadius="8"
      py={{ base: 6, lg: 10 }}
      px={{ base: 6, lg: 12 }}
      {...props}
    >
      {children}
    </Column>
  )
}

export default forwardRef(BoxCard)
