import {
  budgets as budgetsRoute,
  activateCard as activateCardRoute,
  spendControls as spendControlsRoute,
  changePassword as changePasswordRoute,
  freezeCard as freezeCardRoute,
  dashboardHelpCentre as helpCentreRoute,
  myCards as myCardsRoute,
  notification as notificationRoute,
  payments as paymentsRoute,
  paymentHistory as paymentHistoryRoute,
  people as peopleRoute,
  peopleAdd as peopleAddRoute,
  pinReminder as PinReminderRoute,
  profile as profileRoute,
  receipts as receiptsRoute,
  replaceCard as replaceCardRoute,
  settings as settingsRoute,
  statement as statementRoute,
  transactions as transactionsRoute,
  unFreezeCard as unfreezeCardRoute,
  statements as statementsRoute
} from "components/layouts/Authorized/routes"

const pathNames = [
  {
    path: activateCardRoute,
    title: "Activate Card"
  },
  {
    path: budgetsRoute,
    title: "Company Budgets"
  },
  {
    path: spendControlsRoute,
    title: "Spend Controls"
  },
  {
    path: changePasswordRoute,
    title: "Change Password"
  },
  {
    path: freezeCardRoute,
    title: "Freeze Card"
  },
  {
    path: helpCentreRoute,
    title: "Help Centre"
  },
  {
    path: myCardsRoute,
    title: "My Cards"
  },
  {
    path: notificationRoute,
    title: "Notifications"
  },
  {
    path: paymentsRoute,
    title: "Payment"
  },
  {
    path: paymentHistoryRoute,
    title: "Payment History"
  },
  {
    path: peopleRoute,
    title: "People"
  },
  {
    path: peopleAddRoute,
    title: "Add Employee"
  },
  {
    path: PinReminderRoute,
    title: "Pin Reminder"
  },
  {
    path: profileRoute,
    title: "Profile"
  },
  {
    path: receiptsRoute,
    title: "Receipts"
  },
  {
    path: replaceCardRoute,
    title: "Report card lost or stolen"
  },
  {
    path: settingsRoute,
    title: "Settings"
  },
  {
    path: statementRoute(1),
    title: "Monthly statement"
  },
  {
    path: transactionsRoute,
    title: "Transactions"
  },
  {
    path: unfreezeCardRoute,
    title: "UnFreeze Card"
  },
  {
    path: statementsRoute,
    title: "Statements"
  }
]

export default pathNames
