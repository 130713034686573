import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { PaymentDetailDto } from "models/payment"
import prepareHeaders from "network/util/prepare-headers"

import { PAYMENTS_API_BASE } from "./apiNamespaces"
import ApiResponse from "./util/api-response"

export interface SearchPaymemtsRequest {
  from?: string
  to?: string
  min?: string
  max?: string
  searchTerm?: string
}

export interface SearchPaymemtsResponse {
  total: number
  items: PaymentDetailDto[]
}

export const Payments = createApi({
  reducerPath: "paymentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: PAYMENTS_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    getPaymentList: builder.mutation<ApiResponse<SearchPaymemtsResponse>, SearchPaymemtsRequest>({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data
      })
    })
  })
})

export const { useGetPaymentListMutation, reducer, middleware } = Payments
