import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import tagDto from "models/tag"
import prepareHeaders from "network/util/prepare-headers"

import { TAG_API_BASE } from "./apiNamespaces"

export const receiptTag = createApi({
  reducerPath: "receiptTagsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: TAG_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    addTag: builder.mutation<
      {
        success: true,
        data: {
          id: number,
          blobId: number,
          description: string,
          entityId: number,
          legalName: string,
          tradingName: string
        }
      },
      {
        blobId: number,
        description: string,
        entityId: number
      }
    >({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data
      })
    }),
    linkTag: builder.mutation<
      {
        success: true,
        data: tagDto
      }, {
        tagId: number,
        blobId: number
      }
    >({
      query: (data) => ({
        url: "link",
        method: "POST",
        body: data
      })
    }),
    unlinkTag: builder.mutation<
      {
        success: true,
        data: tagDto
      }, {
        id: number
      }
    >({
      query: ({ id }) => ({
        url: `unlink/${id}`,
        method: "DELETE"
      })
    })
  })
})

export const {
  useAddTagMutation,
  useLinkTagMutation,
  useUnlinkTagMutation,
  reducer,
  middleware
} = receiptTag
