import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import BlobDto from "models/blob"
import { GpsTransactionDto as Transaction } from "models/gpsTransaction"
import prepareHeaders from "network/util/prepare-headers"

import { RECEIPTS_API_BASE } from "./apiNamespaces"

export interface GetTransactionsRequest {
  LedgerId: number,
  Page: number;
  axleOnly: boolean;
  PageSize: number;
  SearchTerm: string | null;
  From: string | null;
  To: string | null;
  hasReceipts: boolean | null
}

export interface Data {
  total: number;
  items: Transaction[];
}

export interface GetTransactionsResponse {
  success: boolean;
  data: Data;
}

export const receipts = createApi({
  reducerPath: "receiptsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: RECEIPTS_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    getReceipts: builder.mutation<
      {
        success: boolean,
        data: {
          total: number,
          items: BlobDto[]
        }
      },
      {
        username?: string | null,
        ledgerId?: number | null,
        page?: number,
        pageSize?: number,
        searchTerm?: string | null,
        from?: string | null,
        to?: string | null,
        tags?: string[] | null
      }
    >({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data
      })
    })
  })
})

export const {
  useGetReceiptsMutation,
  reducer,
  middleware
} = receipts
