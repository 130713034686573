import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { GpsTransactionDto as Transaction } from "models/gpsTransaction"
import prepareHeaders from "network/util/prepare-headers"

import { GPS_TRANSACTIONS_API_BASE } from "./apiNamespaces"
import ApiResponse, { ApiArrayData } from "./util/api-response"

export interface GetTransactionsRequest {
  LedgerId: number,
  Page: number;
  axleOnly: boolean;
  PageSize: number;
  SearchTerm: string | null;
  From: string | null;
  To: string | null;
  hasReceipts: boolean | null
}

export interface Data {
  total: number;
  items: Transaction[];
}

export interface Otc {
  success: boolean
  data: {
    accessToken: string
  }
}

export interface GetTransactionsResponse {
  success: boolean;
  data: Data;
}

export const transactions = createApi({
  reducerPath: "gpsTransactionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GPS_TRANSACTIONS_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    getTransactions: builder.mutation<
      ApiResponse<ApiArrayData<Transaction>>,
      GetTransactionsRequest
    >({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data
      })
    }),
    getPendingTransactions: builder.mutation<
      ApiResponse<ApiArrayData<Transaction>>,
      GetTransactionsRequest
    >({
      query: (data) => ({
        url: "pending",
        method: "POST",
        body: data
      })
    })
  })
})

export const {
  useGetTransactionsMutation,
  useGetPendingTransactionsMutation,
  reducer,
  middleware
} = transactions
