import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { GpsTransactionDto as Transaction } from "models/gpsTransaction"
import TagDto from "models/tag"
import prepareHeaders from "network/util/prepare-headers"

import { TAGS_API_BASE } from "./apiNamespaces"

export interface Data {
  total: number;
  items: Transaction[];
}

export interface GetTransactionsResponse {
  success: boolean;
  data: Data;
}

export const tags = createApi({
  reducerPath: "tagsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: TAGS_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    getTags: builder.mutation<
      {
        success: boolean,
        data: {
          items: TagDto[]
          total: number
        }
      },
      {
        page: number,
        pageSize: number,
        searchTerm: string,
        entityId: number
      }
    >({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data
      })
    })
  })
})

export const {
  useGetTagsMutation,
  reducer,
  middleware
} = tags
