import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import prepareHeaders from "network/util/prepare-headers"

import { AUTHENTICATION_API_BASE } from "./apiNamespaces"

export interface Otc {
  success: boolean
  data: {
    accessToken: string
  }
}

export const authentication = createApi({
  reducerPath: "authenticationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: AUTHENTICATION_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    getOTC: builder.mutation<Otc, void>({
      query: () => ({
        url: "accesstoken",
        method: "POST"
      })
    })
  })
})

export const {
  useGetOTCMutation,
  reducer,
  middleware
} = authentication
