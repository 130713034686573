import { Flex, Image, useBreakpointValue } from "native-base"

import logo from "assets/svgs/public-logo.svg"

import BaseLayout from "../Base"

interface IPublicProps {
  children?: JSX.Element
  header?: JSX.Element
  footer?: JSX.Element
  isLogin?: boolean
}

const Public = ({ children, header, footer, isLogin }: IPublicProps): JSX.Element => {
  const flexJustifyContent = useBreakpointValue({ base: "center", sm: "flex-start" })
  const flexAlignItems = useBreakpointValue({ base: "center", sm: "flex-start" })
  const imageSize = useBreakpointValue({ base: "80px", sm: "99px" })

  return (
    <BaseLayout
      bg="tertiary.50"
      isLogin={isLogin}
      header={
        header || (
          <>
            <Flex
              p="5vh"
              pt="5vh"
              pb="1vh"
              justifyContent={flexJustifyContent}
              alignItems={flexAlignItems}
              h="100%"
              width={{ base: "100%", lg: isLogin ? "50%" : "100%" }}
            >
              <Image size={imageSize} resizeMode="contain" testID="Public" src={logo} alt="logo" />
            </Flex>
          </>
        )
      }
      content={children}
      footer={footer}
    />
  )
}

export default Public
